@charset "utf-8";

/*** HEADER ***/
#header {
  position: relative;
  display: flex;
  width: 100%;
  height: 70px;
  padding: 0 50px;
  /*
  border-bottom: 1px solid var(--color-border);
  */
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  z-index: 10;
}
#header.admin { 
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 201;
}
.header_logo {width:20%;max-width:150px;}
.header_logo img {
  width:100%;
  max-width: 115px;
}
.home .header_logo a { color: #fff; }
#header .header_nav,
#header .header_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#header .header_links {
  width:20%;
  max-width:150px;
}
@media (max-width: 1500px) {
  #header .header_logo {
    width:20%;max-width:128px;
  }
  #header .header_links {
    width:20%;
  } 
}
#header .header_links a,
#header .header_links button{
  font-family: 'SamsungOneKorean',sans-serif;
  font-weight: normal;
  color: var(--color-border);
}
/* #header .header_nav {width: 10%;} */
#header .header_nav a {
  font-size: var(--font-subtitle);
  font-weight: bold;
  /* padding: 0 20px; */
  color: #9A9A9A;
}
#header .header_nav a.btn-faq {margin-left: 90px;}
#header .header_nav a.active {
  color: #fff;
}
#header .header_nav .depth2 a {
  font-size:0.925em;
  color: #ffffff;
  font-weight:500;
  opacity: 0.75;
}
#header .header_nav .depth2 a.active {
  font-weight:700;
  opacity: 1;
}

#header .header_links {
  display: flex;
  column-gap: 15px;
  align-items: center;
}
#header .header_links .links-acc-bar{
  display: inline-block;
  width: 1px;
  height: 12px;
  background-color: var(--color-grey);
}
#header.home {
  position: fixed;
  top: 0;
  color: #fff;
}

/**헤더메뉴**/
.menu { 
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.depth1 li:not(:first-of-type) a {margin-left: 90px;}
.menu > li {
  display: flex;
}

.menu li a {
  position: relative;
  display: block;
  line-height: 70px;
}
#header .header_nav a:hover::after,
#header .header_nav a.active::after {
  content:"";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--color-blue);
}
.menu > li > a span { 
  position: relative;

}
.menu > li:hover .depth2 {
  display: flex;
  z-index: 10;
}

.menu > li > a.active + .depth2 {
  display: flex;
  background: #000;
  z-index: 9;
}

.depth2 {
  display: none;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 70px;
  left: 0;
  /* transform: translateX(-50%); */
  width: 100%;
  height: 50px;
  background-color: #000000;
  z-index: 100;
  /* box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3); */
}

.depth2 a.active {
  color: var(--color-blue);
}
#header .header_nav .depth2 a:hover {
  opacity: 1;
}

.modal {
  z-index: 99999;
}