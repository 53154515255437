@charset "utf-8";
.modal-dialog_xlg {width: 1148px !important;}
.modal-dialog_xlg .modal-body {padding: 30px !important;}
.adm_login_logo {
  position: fixed;
  top: 25px;
  left: 50px;
}
.adm_login_logo img {
  width:100%;
  max-width: 115px;
}
.nav_wrap-menu-bar {
  display: inline-block;
  width: 10px;
  height: 1px;
  margin-right: 15px;
  vertical-align: middle;
  background-color: #666;
}
/* .form input[type=text],
.form input[type=password],
.form input[type=email],
.form input[type=number],
.form select {border-bottom: 1px solid #D3D3D3 !important;} */
.container_adm .form input[type=text]:focus-visible,
.container_adm .form input[type=password]:focus-visible,
.container_adm .form input[type=email]:focus-visible,
.container_adm .form input[type=number]:focus-visible,
.container_adm .form select:focus-visible {border-bottom: 1px solid #D3D3D3 !important;}
/* .container_adm .form .vip_invitee input[type='text'] {width: 300px;} */
.container_adm .textarea_adm {
  width: 100%;
  padding: 10px;
  border: 1px solid #D3D3D3;
  border-radius:4px;
}
.vip-reservation-tit {
  border-bottom: 2px solid #000;
}
.container_adm .vip-reservation .form .form_inner,
#modalTicketView .form .form_inner  {display: block;}
.container_adm .vip-reservation .form .form_item,
#modalTicketView .form .form_item  {width: 100%;}
.container_adm .vip-reservation .form_item .list_label,
#modalTicketView .form_item .list_label {margin-right: 24px;}
.vip_invitee {
  flex-direction: row !important;
  align-items: center;
}
.vip_invitee div {width: 280px;}
#modalTicketView .view-four, .container_adm .modal_view .view-four {width: 280px;}
#modalCancel .btn-cancel {background-color: #000;}
#modalCancel .btn-done {margin-right: 20px;}
.table_adm .two {
  width: 154px;
  width: calc(1455px / 8);
  font-size: 13px;
}
.table_adm td > table {
  width: 100%;
  max-width: 135px;
}
.table_adm td > table tr,
.table_adm td > table tr td {
  padding: 10px 8px;
  border: 0;
}
.table_adm td>table tr td:has(.btn-td-sm) {padding: 0 2.5px;}
.table_adm td > table .bg_blue, 
.table_adm td > table .bg_green {padding: 8px 40px;}
.btn-td-sm {
  width: 120px;
  padding: 8px 18px;
  border: 1px solid #000;
  border-radius: 50px;
  background-color: #fff;
  font-size: 13px;
}
.btn-td-sm--blue {
  border: 1px solid var(--color-blue);
  background-color: var(--color-blue);
  color: #fff;
}
.btn-td-more {
  display: flex;
  width: 120px;
  padding: 5px 4px 3px 6px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 50px;
  font-weight: 700;
  background-color: #fff;
}
.btn-td-more span {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #F23434;
  color:#fff;
}

.adm_wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.adm_contents {
  color: #fff;
}

.adm_contents .login_wrap {
  max-width: 854px;
  margin: 0 auto
}

.adm_contents .login_wrap input {
  width: 330px;
  height: 45px;
  border-bottom: 0;
  border-radius: 5px;
}

.adm_title {
  text-align: center;
}

input[type="checkbox"].chk_black,
input[type="radio"].chk_black,
.container_adm .form input[type="radio"].chk_black,
.container_adm .search_wrap .multi-select .dropdown-content input[type="checkbox"] {
  width: 20px;
  height: 20px !important;
  padding: 0;
  border: 1px solid #000;
  border-radius: 0;
  /* vertical-align: middle; */
}
input[type="checkbox"].chk_black:checked,
input[type="radio"].chk_black:checked,
.container_adm .form input[type="radio"].chk_black:checked,
.container_adm .search_wrap .multi-select .dropdown-content input[type="checkbox"]:checked {
  background: url(@/assets/images/checked.png) no-repeat 60% center;
  border: 1px solid transparent;
}
.container_adm .search_wrap .multi-select .dropdown-content label {padding: 12px;}
.container_adm .search_wrap .multi-select .dropdown-content label:hover {background: none;}
.container_adm .search_wrap .multi-select .dropdown-content input[type="checkbox"] {position: relative;top: 4px;margin-right: 6px;}
.btn-reset {
  width: 39px;
  height: 39px;
  aspect-ratio: 1/1;
  border: 1px solid #000;
  border-radius: 50%;
}
.btn-reset img {
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
}
.login_wrap-inputs {
  display: flex;
  align-items: flex-end;
}
.adm_label {position: relative;}
.adm_label > div .alert {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin-top: 0;  
  line-height: 0;
  border: 0;
}
#nav_wrap {
  position: fixed;
  width: 220px;
  height: calc(100vh - 70px);
  min-height: 100%;
  top: 70px;
  left: 0;
  background-color: #000;
  color: #fff;
  z-index: 9999;
}

#nav_wrap a,
#nav_wrap button {
  font-family: 'SamsungOneKorean', sans-serif;
}

#nav_wrap .nav_wrap-menus {}

#nav_wrap .nav_wrap-menu>a,
#nav_wrap .nav_wrap-menu>button {
  display: block;
  width: 100%;
  padding: 15px 28px;
  border-top: 1px solid #666;
  font-weight: normal;
  text-align: left;
  color: #fff;
  /* background: url(@assets/images/icon_arrow-down-g.svg) no-repeat calc(100% - 20px) center/11px; */
}

#nav_wrap .nav_wrap-menu:last-of-type>a {
  background: none;
}

#nav_wrap .nav_wrap-menu > a.active {
  background: #333;
}

#nav_wrap .nav_wrap-menu>a.active {
  /* background: url(@assets/images/icon_arrow-up-g.svg) no-repeat calc(100% - 20px) center/11px #333; */
}

#nav_wrap .nav_wrap-menu:last-of-type>a {
  border-bottom: 1px solid #666;
}

/* #nav_wrap .nav_wrap-submenus {display: none;} */
#nav_wrap .nav_subwrap {
  display: none;
  width: 100%;
  padding: 0 20px;
}

#nav_wrap .nav_subwrap-menu {
  position: relative;
  display: block;
  padding: 15px 0 15px 25px;
  color: rgba(255, 255, 255, .5)
}

#nav_wrap .nav_subwrap-menu>span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 1px;
  background-color: rgba(255, 255, 255, .5);
}

/* #nav_wrap .nav_subwrap-menu::before {
  content:"";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 1px;
  background-color: rgba(255,255,255,.5);
} */
#nav_wrap .nav_subwrap-menu.on {
  color: #fff;
}

#nav_wrap .nav_subwrap-menu.on span {
  background-color: var(--color-blue);
}

#nav_wrap .nav_wrap-top {
  padding: 40px;
  text-align: center;
}

#nav_wrap .user_id {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.btn-log {
  padding: 6px 24px;
  border-radius: 20px;
  background-color: var(--color-blue);
  font-size: var(--font-desc);
  font-weight: bold;
  color: #fff;
}

.container_adm {
  padding: 120px 60px 100px 280px;
}
.container_adm__inner {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
}

.search_wrap {
  display: flex;
  column-gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
}

.search_wrap2 {
  justify-content: space-between;
  column-gap: normal;
  padding-bottom: 20px;
}

.search_wrap-inner {
  display: flex;
  column-gap: 10px;
}

.search_wrap-inner .input_search-wrap {
  max-width: unset;
}
.container_adm .input_search-wrap input {border: 1px solid #E0E0E0; height: 39px;}
/* .search_wrap select,
.search_wrap2 select {
  width: 100%;
  max-width: 20%;
  padding: .4em;
  border: 1px solid var(--color-border);
} */

.input_search-wrap {
  position: relative;
  width: 100%;
  max-width: 20%;
}

.input_search-wrap .input_search {
  width: 100%;
  height: 100%;
}

.input_search-wrap>button {
  position: absolute;
  top: 50%;
  left: calc(100% - 18px - .8em);
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: url(@/assets/images/icon_search.svg) no-repeat center/contain;
}

.container_adm_faq .search_wrap {
  justify-content: right;
}

/* .container_adm .form input[type='text'] {
  width: 340px;
  height: 43px;
  border-radius: 6px;
} */
/* .container_adm input[type='text']:disabled,
.container_adm input[type='date']:disabled,
.container_adm select:disabled{background-color: #FAFAFA !important;}
*/
/* .container_adm input[type='text'],
.container_adm input[type='email'],
.container_adm input[type='number'],
.container_adm input[type='date'],
.container_adm .search_wrap select{
  width: 100%;
  min-width: 150px;
  padding: 10px;
  border: 1px solid #E0E0E0;
}  */
.container_adm .adm_title-wrap > div {
  width:100%;
}
.container_adm .adm_title-wrap select {
  min-width: 100px;
  /* max-width: 200px; */
  padding: .6em 0.8em;
  border: 1px solid var(--color-border);
}

.container_adm .adm_title-wrap h2 {
  display: inline-block;
  vertical-align: middle;
}

.container_adm .adm_title-wrap h2 + select {
  margin-right: 10px;
  float:right;
}

/* .container_adm select {
  width: 350px;
  height: 39px;
  border: 1px solid #D3D3D3;
  border-radius: 6px;
  background: url(@/assets/images/icon_arrow-down.svg) no-repeat calc(100% - 1em) center/11px #fff;
} */
.container_adm .form {text-align: left;}
/* .container_adm select, 
.container_adm .form select,
.container_adm .form input[type=password] {
  width: 350px;
  height: 43px;
  border: 1px solid #D3D3D3;
  border-radius: 6px;
  background: url(@/assets/images/icon_arrow-down.svg) no-repeat calc(100% - 1em) center/11px #fff;
}*/
.container_adm .container_body-tit {border-bottom: 1px solid #000;}
.container_adm .form,
#modalTicketView .form {padding: 50px 0 60px;}
.container_adm .form .form_item,
#modalTicketView .form .form_item {
  display: flex;
  margin-bottom: 32px;
  align-items: flex-end;
}
.container_adm .form_item .list_label,
#modalTicketView .form_item .list_label {
  /* width: 343px;
  min-width: 343px;
  max-width: 343px; */
  border-bottom: 1px solid #bdbdbd;
}
#modalTicketView .container_adm .form .bar {background-color: #d3d3d3;}
#modalTicketView .react-datepicker-wrapper,
#modalTicketView .container_adm .search_wrap select,
#modalTicketView .multi-select {width: 184px;}
#modalTicketView .container_adm .search_wrap .input_search-wrap:last-of-type {width: 204px;}

.tour_calendar .table_adm td:has(.cal_table_wrap) {
 vertical-align: top; 
}

.table_adm {
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0; 
  /* font-size:0.9em; */
}

.table_adm thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: #000;
  color: #fff;
  font-family: 'Samsung Sharp Sans', sans-serif;
}

.table_adm thead th {
  padding: 15px 0;
  border-top: 1px solid #666;
  border-right: 1px solid #666;
  background-color: #000;
}

.table_adm.summary thead th {
  padding: 10px 0;
}

.table_adm thead th.two {
  top: 118px 
}

.table_adm thead input[type="checkbox"] {
  border: 1px solid #ffffff;
  background-color: transparent;
}

.table_adm thead input[type="checkbox"]:checked {
  border: 1px solid var(--color-blue)
}

.table_adm tbody tr {
  border-bottom: 1px solid #d3d3d3;
}

.table_adm tbody tr:hover td {
  background-color: #F7F7F7;
}

.table_adm tbody tr td {
  padding: 15px 10px;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}
.table_adm tbody td button {
  font-size: 13px ;
}
.table_adm input::placeholder {
  text-align: center;
}
.container_adm .table_adm .input_none {
  padding: 0 10px;
  border:0;
  border-radius: 0;
  background: none;
}
.container_adm input::placeholder,
.container_adm .table_adm .input_none::placeholder {
  text-align: left;
}
.table_adm td:has(.tour_memo) {position: relative;}
.tour_memo {
  display: flex;
  min-width: 80px;
  width: 100%;
  height: 100%;
  padding: 15px 0;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-weight: 500;
  color: var(--color-grey);
}
.tour_memo_box {
  position: absolute;
  left: -25px;
  bottom: 33px;
  display: none;
  width: 270px;
  height: 122px;
  background: url(@/assets/images/bg_bgmemo.png) no-repeat center/contain;
  z-index: 99;
}
.tour_memo.open ~ .tour_memo_box {display: block;}
.tour_memo_box__colors {
  display: flex;
  padding: 15.5px 23.5px 0;
  margin-bottom: 7px;
  align-items: center;
  font-weight: 500;
  white-space: nowrap;
}
.tour_memo_box input[type="text"] {
  width: 84%;
  height: 39px;
  padding: 9px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 0 !important;
  text-align: left;
}
.tour_memo_box input[type="text"]::placeholder {text-align: left;}
.form .tour_memo_box {

}
.container_adm .tour_memo_box__colors input[name='memoBg'], .container_adm .tour_memo_box__colors input[name='label.bgColor'] {
  position: relative;
  width: 21px !important;
  min-width: 21px;
  height: 21px !important;
  padding: 0;
  border: 1px solid #000;
  margin:0 0 0 2px;
  border-radius: 50% !important;
  background-color: #fff;
  background-image: none !important;
}
.container_adm .tour_memo_box__colors input[name='memoBg']::after , .container_adm .tour_memo_box__colors input[name='label.bgColor']::after {
  content:"";
  position: absolute;
  top: 2px;
  left: 2px;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.tour_memo_box__colors input[id='BgWhite']::after,
.tour_memo[data-color='red'] {background-color: #fff}
.tour_memo_box__colors input[id='BgRed']::after,
.tour_memo[data-color='red'] {background-color: #F23434;}
.tour_memo_box__colors input[id='BgGreen']::after,
.tour_memo[data-color='green'] {background-color: #00AA5A;}
.tour_memo_box__colors input[id='BgBlue']::after,
.tour_memo[data-color='blue'] {background-color: #1428A0;}
.tour_memo_box__colors input[id='BgGold']::after,
.tour_memo[data-color='gold'] {background-color: #FF9E20;}
.tour_memo_box__colors input[id='BgBlack']::after,
.tour_memo[data-color='black'] {background-color: #000;}
.tour_memo_box__colors input[id='BgGrey']::after,
.tour_memo[data-color='grey'] {background-color: #9A9A9A;}

.tour_memo[data-color='red'],
.tour_memo[data-color='green'],
.tour_memo[data-color='blue'],
.tour_memo[data-color='gold'],
.tour_memo[data-color='black'],
.tour_memo[data-color='grey'] {color: #fff;}

.container_adm .tour_memo_box__colors input[id='BgWhite']:checked {border: 1px solid #fff;}
.container_adm .tour_memo_box__colors input[id='BgRed']:checked {border: 1px solid #F23434;}
.container_adm .tour_memo_box__colors input[id='BgGreen']:checked {border: 1px solid #00AA5A;}
.container_adm .tour_memo_box__colors input[id='BgBlue']:checked {border: 1px solid #1428A0;}
.container_adm .tour_memo_box__colors input[id='BgGold']:checked {border: 1px solid #FF9E20;}
.container_adm .tour_memo_box__colors input[id='BgBlack']:checked {border: 1px solid #000;}
.container_adm .tour_memo_box__colors input[id='BgGrey']:checked {border: 1px solid #9A9A9A;}


.table_ticket tbody tr:hover {
  background: none;

}
.td-justify{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.td-justify > span {
  display: inline-block;
  width: 100%;
}
.adm_title-wrap {
  display: flex;
  height: 38px;
  justify-content: space-between;
  align-items: center;
}

.adm_title-wrap .btn-excel {
  padding: 10px 30px;
  float:right;
  min-width:180px;
}

.table_point strong {
  display: block;
  margin-bottom: 5px;
  color: var(--color-blue);
}

.container_adm .select_status {
  padding: 11px 12px;
  width: 100%;
  max-width: 110px;
  color: #fff;
  font-size: var(--font-desc);
  font-weight: 500;
  background: url(@/assets/images/icon_arrow-down-g.svg) no-repeat 93% center/10px #212121;
  letter-spacing: -0.5px;
}

.container_adm .buttons_center button {
  width: 200px;
  height: 50px;
  padding: initial;
  line-height: initial;
}

.container_adm .buttons_center button:disabled {
  /*
  background-color: var(--color-grey);
  color: rgba(255, 255, 255, .5);
  border: none;
    */
}
.container_adm .vip-reservation-tit {border-bottom: 2px solid #000;}
.container_adm .vip-reservation-tit h4 {
  /* font-size: 20px; */
}
.td_sp {
  display:block;
  width:10%;
  font-size:0;
  padding:3px 0;
}

.rmsc .dropdown-content {
  z-index: 10000;
}

/* checkbox */
/* .tour_calendar input[type="checkbox"] {
  width: 43px !important;
  height: 43px !important;
  padding: 0;
  border: 1px solid #000 !important;
  background: #fff !important;
  vertical-align: middle;
}

.tour_calendar input[type="checkbox"]:checked.bg_green {
  background: #00AA5A !important;
  border: 1px solid transparent;
}

.tour_calendar input[type="checkbox"]:checked.bg_blue {
  background: #1428A0 !important;;
  border: 1px solid transparent;
} */

.tour_calendar .table_adm tbody tr td {padding: 0;}
.td_mh {display: flex; min-height: 125px;}
.cal_table_wrap {flex-direction: column;
}
.cal_table_wrap table tr {border-bottom: 0;}
.cal_table_wrap table tr td {padding: 10px 8px 12px !important;border: 0;}
.rsv_item {border-bottom: 1px solid #eee;}
.cal_table_status {padding: 7px 0;}
.table_adm .cal_table_btns td {padding: 0 !important;}
.cal_table_btns .btn-td-sm {margin-bottom: 12px;}
/* .cal_table_btns button {margin-right: -6px;}
.cal_table_btns a {margin-left: -6px;} */
.cal_table_btns .sssb {
  position: relative;
  top: 1px;
  font-size: 13px !important;
}
.container_adm .btn-remove {min-height: 42px;min-width: 42px;}

.bg_gray {
  background-color: #a1a1a1 !important;
}
.bg_red {
  background-color: #F23434 !important;
}
.bg_green {
  background-color: #00AA5A !important;
}
.bg_blue {
  background-color: #1428A0 !important;;
} 
.bg_black {
  background-color: #000 !important;
}
.bg_white {
  background-color: #fff !important;
}
.fc_white {
  color: #fff;
}


/* 230714 */
.w100x {width: 100px !important;}
.w300x {width: 300px !important;min-width: 300px !important;}
.w322x {width: 322px !important;min-width: 322px !important;}
.w350x {width: 350px !important;min-width: 350px !important;}
.cal_plus_wrap {align-items: center;justify-content: center;}
.container_adm .form select, .container_adm .form input {width: 100%;height: 42px !important;padding: 10px;border: 1px solid #D3D3D3;border-radius: 6px;}
.container_adm .form input[type="checkbox"] {width: 20px;height: 20px !important;}
.container_adm .select_adm,
.container_adm .rsvEditForm select{width: 100%;height: 39px !important;padding: 8px 10px;border: 1px solid #E0E0E0;background: url(@/assets/images/icon_arrow-down-g.png) no-repeat calc(100% - 1em) center/11px #fff;}
.container_adm .select_adm.icon_cal {background: url(@/assets/images/icon_s5.svg) no-repeat calc(100% - 1em) center/11px #fff;}
.container_adm .select_adm.bc_red {border: 3px solid red !important;}
.container_adm .select_adm.br6 {border-radius: 6px;}
.container_adm .table_adm input[type="checkbox"].chk_white {border: 1px solid #fff;}
.container_adm .table_adm input[type="checkbox"].chk_white:checked {border: 1px solid transparent;}
.input_changepw {max-width: 183px; height: 36px;border: 1px solid #E0E0E0;}
.select_pcharge {height: 36px !important;width: 68px;min-width: 68px;}
.container_adm .input_changepw::placeholder {text-align: center;}
.adm-rsv-view .modal_view small {font-weight: normal;}
.adm-rsv-view .modal_view .modal_value {border-bottom: 1px solid #e4e4e4;}
.container_adm .input_search-wrap {width: 204px;}
.container_adm .input_search-wrap:first-of-type {width: 271px;}
.container_adm .search_wrap .input_search-wrap ~ select,
.container_adm .search_wrap .multi-select {width: 184px;}
.container_adm .search_wrap .multi-select .dropdown-container{border-radius: 0;}
.container_adm .search_wrap .multi-select .dropdown-container{border: 1px solid #e0e0e0}
.container_adm .form .form_inner .form_item>div:has(.select_adm),
.container_adm .form .form_inner .form_item>div:has(input[type="text"]) {width: 100%;}
.container_adm .table_adm .btn-view /*,
.container_adm .table_adm .btn-modify-r*/ {padding: 7px 25px;}
.container_adm .buttons-center {
  display: flex;
  width: 100%;
  padding-left: 0;
  justify-content: center;
}
.container_adm .buttons-center .btn-submit,
.container_adm .buttons-center .btn-cancel,
.container_adm .buttons-center .btn-mail,
.container_adm .buttons-center .btn-vvip {display: inline-flex;padding: 0 2em; justify-content: center;align-items: center; height: 50px;background-color: #fff;white-space: nowrap;min-width: 150px;}
/* .container_adm .buttons-center button {width: max-content;} */
.buttons-center.fixed {position: fixed; bottom: 40px;left: 0;padding-left: 220px;  }
.adm_rsv_table thead th {padding: 15px 7px;}
#cb_approve, #cb_priority {width: 39px;height: 39px;border-radius: 0;}
#cb_approve:checked, #cb_priority:checked {background: url(@/assets/images/checked-big.png)}
#nav_wrap .nav-wrap-2dep .nav-wrap-menu2 a {color: #666;}
#nav_wrap .nav-wrap-2dep .nav-wrap-menu2 a.active {background:none;color: #fff;}
#nav_wrap .nav-wrap-2dep .nav-wrap-menu2 a.active .nav_wrap-menu-bar{background-color: #fff;}

/* 230717 */
.dropdown-heading-dropdown-arrow {transform: scale(.8);color:#777 !important;}
.multi-select{z-index: 99;}
.rmsc .gray {color: inherit !important}
.adm-rsv .form .bb,.adm-rsv .form .bb {padding-bottom: 0;border-bottom: 0;}
.adm-rsv .form .form_item>div:has(.select_adm) {width: 100%;}

/* 230718 추가 */
@media print {
  .adm-rsv-view .form {padding: 0;}
  .adm-rsv-view .form .form_item {margin-bottom: 20px;}
  .adm-rsv-view .vip-reservation-tit.mb60 {margin-bottom: 25px !important;}
  .adm-rsv-view .modal_view .view-four:has(.list_label) {padding-top: 0 !important;}
  .adm-rsv-view .modal_view .view-four {width: 22% !important;}
  .adm-rsv-view .list_label span {vertical-align: middle;}
  .adm-rsv-view .modal_view small {font-size: 90%;}
}

/* 230802 추가 */
.container_adm .buttons-center .btn-vvip {
  border-radius: 25px;
  background-color: #FF9C07;
  color: #fff;
}
.status_wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.status_wrap.vvip::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  display: block;
  width: 40px;
  height: 22px;
  transform: translateX(-50%);
  background: url(@assets/images/icon_vvip.svg) no-repeat center;
  z-index: 3;
}
.rsv_item .status_wrap.vvip::after {
  top: 49%;
  right: 5px;
  left: unset;
  transform: translateY(-50%);
}

/* 230804 */
.container_adm .select_adm, .container_tour .form select {border-radius: 0;}
#cb_priority  {min-width: 39px;}
.adm_title-wrap .btn-excel {min-width: 185px;padding: 10px 1.8em; white-space: nowrap;}


/* CES 김시윤 */
.btn-login.adm {
  width: 150px !important;
  min-width: 150px;
  height: 45px;
  border-radius: 5px !important;

}
.adm_label p {color: var(--color-border)}
.index_title.adm {font-size: 84px;}

#nav_wrap {width: clamp(185px,11.45vw,250px);}
.container_adm {
  padding-right: clamp(20px,3.12vw,90px);
  padding-left: clamp(220px,14.58vw,300px);
}
/* .container_adm button {font-size: var(--font-default);} */
.input_changepw {width: clamp(127px,9.375vw,190px);}
.table_adm tbody tr td {
  line-height: 1.6;
  padding: calc(var(--font-default)*0.87) 0.6666em;
}

.table_adm.summary tbody tr td{
  padding: 10px 0;
}

/* .container_adm .react-datepicker-wrapper {border: 1px solid #E0E0E0; height: 39px;} */
.container_adm .react-datepicker-wrapper {position: relative;}
.container_adm .react-datepicker__close-icon {display: none;}
.table_adm tbody td button {font-size: var(--font-desc);}
.search_wrap2 .react-datepicker-wrapper, .search_wrap2 select.select_adm {width: 184px;}
.search_wrap2 .input_search-wrap {width: clamp(220px,15.5vw,300px);}
.container_adm .input_search-wrap input {padding-right: 28px;}
.container_adm .react-datepicker__tab-loop {
  position: absolute;
  /* left: -9999px; */
}
#modalCancel .btn-done {max-width: 200px;}
.container_adm .btn-change {
  width: 200px;
  height: 40px;
  padding: 0;
}
#modalTimeChosen h3 {font-size: var(--font-title);}
.container_adm .form_item .list_label,
#modalTicketView .form_item .list_label {
  width: 284px;
  min-width: 284px;
  max-width: 284px;
}
.rsvEditForm .form_item .list_label, 
.admRsvForm .form_item .list_label {margin-right: 24px;}
.rsvEditForm .form_inner .form_item>div, 
.admRsvForm .form_inner .form_item>div {width: 100%;}
.mpMobile-phone select {max-width: 220px;}
.rsvEditForm .mpMobile-phone select,
.admRsvForm .mpMobile-phone select {width: 220px;margin-right: 0;}
.rsvEditForm .mpMobile-phone input[type="text"],
.admRsvForm .mpMobile-phone input[type="text"] {
  width: 100%;
  margin-right: 0;
}
.rsvEditForm .form_inner .mpMobile-phone>div {width: auto;}
.form.signup .form_item .useId_wrap span {padding: 0 10px;}
/* .status_wrap.vvip {top: 10px;} */
.container_adm .buttons-center button {
  min-width: auto !important;
  width: 10.41vw;
  max-width: 200px;
}
.btn-td-sm {
  display: inline-flex;
  width: clamp(45px,3.49vw,80px);
  align-items: center;
  justify-content: center;
}

.table_adm tbody td button {font-size: var(--font-desc) !important;}
.container_adm .cc__wrap {max-width: 220px;}
.container_adm .cc__input {
  top: calc(100% + 10px);
  width: 220px;
}
.container_adm .cc__wrap select {
  height: 42px !important;
  border: 1px solid #d3d3d3;
}