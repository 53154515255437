@charset "utf-8";

#root{
  width: 100%;
  /** 반응형 없이   **/
  min-width:1500px;
  height: 100%;
  min-height: 100%;
}

/*** 테이블 ***/
.table_list,
.table_list2 {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
}

.table_list thead,
.table_list2 thead {
  position: relative;
  color: #fff;
}

.table_list thead::after {
  /* content: ""; */
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  z-index: -1;
}

.table_list thead tr {
  border-radius: 8px;
  overflow: hidden;
}

.table_list thead th,
.table_list2 thead th {
  padding: 15px 6px;
  background-color: #000;
}

.table_list tbody tr {
  border-bottom: 1px solid var(--color-border);
}

.table_list tbody td {
  padding: 15px 0;
}

.table_list2 thead {
  text-align: center;
}

.table_list2 thead::after {
  /* content: ""; */
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #212121;
  border-radius: 8px;
  z-index: -1;
}

/*** Foot ***/
.footer_wrap {
  width: 100%;
  padding: 25px 0;
  color: #BDBDBD;
  text-align: center;
}

.footer_wrap.home {
  position: fixed;
  bottom: 0;
  color: #BDBDBD;
}

/* 인덱스 */
/* 인덱스 */
.index_container {
  position: relative;
  top:45%;
  left:50%;
  transform: translate(-50%, -50%);
  width:70%;
}
ul.index_inenr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
}
ul.index_inenr > li {
  border-bottom:2px solid #000;
}
/* ul.index_inenr.vip, ul.index_inenr.ticket {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
}
ul.index_inenr.vip > li, ul.index_inenr.ticket > li {
  width: 40%;
} */
ul.index_inenr {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
ul.index_inenr > li {
  width: 40%;
}
li.login_no_board {
  border-bottom:none !important;
}



.index_title {
  width:100%;
  padding-bottom: 100px;
  font-size: 70px;
  font-size: clamp(54px,3.64vw,80px);
  text-align:center;
}
.index_title .co {
  color: var(--color-blue);
}
.index_title > p {
  font-size:0.83333em;
}
.index_list {
  display: flex;
  /* min-width: 540px; */
  padding: 37px 0 38px 0;
  align-items: center;
  justify-content: space-between;
  transition: background-color .4s;
  cursor:pointer;
}

.btn_enter {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: url('@assets/images/icon_enter.svg') no-repeat center/30px;
  border: 2px dotted var(--color-blue);
  border-radius: 50%;
  transition: all .4s;
  cursor: pointer;
}

.index_list .btn_enter::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px dotted var(--color-blue);
  border-radius: 50%;
  opacity: 0;
  transition: all 1s;
}

.index_list:hover {
  background-color: #fff;
  color: #282828;
}

.index_list:hover .btn_enter {
  background: url('@assets/images/icon_enter-white.svg') no-repeat center/30px var(--color-blue);
  border: 2px solid var(--color-blue);
}

.index_list:hover .btn_enter::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 90px;
  height: 90px;
  border: 1px dotted var(--color-blue);
  border-radius: 50%;
  opacity: 1;
}

.forgot_input {
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.forgot_input input,
.forgot_input select {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}
#modalFindPw {
  padding-right: 15px;
}

h3.mo_h3 {
  padding-bottom: 34px;
  font-size: 24px;
}

.modal-header {
  justify-content: right;
}

#modalFindPw .modal-body {
  padding: 0 30px 20px;
  text-align: center;
}

.modal-content-p0 {
  text-align: center;
}

.modal-content-p0 .modal-body p {
  margin-bottom: 9px;
}
#modalCancel .modal-body {
  /* padding: 15px 45px 65px 45px; */
  padding: 77px 30px 58px;
}
#modalEmailDbchk .modal-body {
  padding: 95px 45px 65px;
}
.modal .fw-md {
  font-weight: 400;
}

.btn-modalclose {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(@/assets/images/icon_remove.svg) no-repeat center/22px;
  cursor: pointer;
}

#modalTicketView .btn-modalclose,
#modalInviteesView .btn-modalclose {
  position: absolute;
  top: 20px;
  right: 20px;
}

#modalTicketView .btn-print,
#modalInviteesView .btn-print {
  position: absolute;
  top: 20px;
  right: 55px;
}

#modalTicketView .modal-title,
#modalInviteesView .modal-title,
#modalFloorPlan .modal-title {
  width: 100%;
}

#modalTicketView .modal-footer,
#modalInviteesView .modal-footer {
  padding-top: 20px;
}
#modalInviteesView .btn-close {
  width: 50%;
}

/* 서브 */
.container {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 40px 0 120px;
}

.container_top {
  position: relative;
  height: 225px;
  font-weight: bold;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}

.container_top::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container_top .top-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.container_top .top-info_cn {
  top: calc(50% + 0px);
}
.container_top .top-cate {
  display: inline-block;
  padding: 10px 54px;
  margin-bottom: 25px;
  border: 1px dashed #fff;
  border-radius: 20px;
}

.container_top .top-title {
  white-space: nowrap;
  font-size: var(--font-large);
}

.container_top .top-subtitle {
  white-space: nowrap;
  font-size: var(--font-subtitle);
}

.container_lang-wrap {
  display: flex;
  padding-bottom: 10px;
  justify-content: right;
  border-bottom: 1px solid #BDBDBD;
}

.container_lang {
  display: flex;
  font-size: var(--font-desc);
}

.container_lang button {
  padding: 8px 17px;
  background-color: #000;
  color: #808080;
}

.container_lang button.on {
  background-color: var(--color-blue);
  color: #fff;
}

.container_body-tit {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border);
}

.container_body-tit h3 {
  font-size: var(--font-title);
  letter-spacing: 0;
}

.tit-desc {
  color: var(--color-blue);
}
span.tit-desc-p {
  position: relative;
  bottom:-2px;
  margin:0 12px 0 5px !important;
  background:none !important;
  color: var(--color-blue);
}
span.tit-desc-box-p {
  position: relative;
  bottom:-2px;
  margin:0 5px 0 0px !important;
}
.tit-desc-box {
  padding:5px 18px;
  border-radius:20px;
  color:#fff;
  font-weight:600;
  background: var(--color-blue);
}
.form {
  width: 100%;
  max-width: 1030px;
  padding: 60px 0;
  margin: 0 auto;
}

.form.ticket_form {
  max-width: 100%;
  padding: 0;
}

.form.ticket_form .form_item_group {
  max-width: 1030px;
  margin: 0 auto;
  padding: 60px 0;
}
/* 
.form.signup .form_item>* {
  width: 33.3333%;
  max-width: 323px;
} */
.form_item-ph > div{width: 499px}
.form_item__wrap div:has(select[name="pic.countryCode"]),
.form_item__wrap div:has(select[name="site.countryCode"]) {width: 160px;}
.form_item__wrap div:has(input[name="pic.mobile"]),
.form_item__wrap div:has(input[name="site.mobile"]) {width: 319px;}

/* .form.signup .form_item:nth-child(6) > div:nth-child(3) { width: 41.6667%; } */

.form.ticket_form .form_item_group .form_item > * {
  width: calc(33.3333% - 20px);
}

/* .form.ticket_form .form_item_group .form_item:nth-child(3)>div:nth-child(2), */
/*
.form.ticket_form .form_item_group .form_item:nth-child(4) > div:nth-child(2) {
  width: calc(66.6666% + 10px);
}
*/
.form.ticket_form .form_item_group .form_item:nth-child(3) > div:nth-child(2),
.form.ticket_form .form_item_group .form_item:nth-last-child(2) > div:nth-child(2){
  width: calc(33.3333% - 20px);
}
/* .form.ticket_form .form_item_group .form_item:nth-last-child(2)>div:nth-child(2) {
  width: calc(66.6666% + 10px);
} */

.form.ticket_form .form_item_group .form_item:last-child > div:nth-child(2) {
  width: 25%;
}

.form.ticket_form .form_item_group .form_item:last-child > div:nth-child(3) {
  width: 41.6667%;
}

.form.ticket_form .form_item_group .form_item button {
  width: 114.88px;
}

.form .form_item_group .form_item > div {
  width: 100%;
}
.form .form_inner .form_item > div {
  align-items: flex-end;
}
.form_item__wrap-top {
  align-items: start !important;
}
.container_adm .form .form_inner .form_item > div {
  /* width: 100%; */
}
.form .form_item:last-of-type > div:nth-child(1) {
  width: calc(20% - 20px) !important;
  border:1px solid #ff0000;
}
.form .mp-input1-00 .form_item > div {
  width: 100%;
}
/* .mpMobile-phone > div:nth-child(2){
  width: 25% !important;
}
.mpMobile-phone > div:nth-child(3){
  width: 50% !important;
} */

.form .form_item {
  /* display: flex; */
  margin-bottom: 40px;
}

.form .form_item>span {
  line-height: 42px;
}

.form .form_item:last-of-type {
  margin-bottom: 0;
}

.form .form_item_chk {
  align-items: baseline;
}

.field_msg {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

/* .field_msg span{letter-spacing: -0.4px;} */
.field_msg span::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: .5em;
  background: url(@/assets/images/icon_chk-warn.svg) no-repeat left center;
  vertical-align: middle;
}

.container .form input[type=text],
.container .form input[type=password],
.container .form input[type=email],
.container .form input[type=number],
.container .form select {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}
.container_tour .form input[type=text]:focus-visible,
.container_tour .form input[type=password]:focus-visible,
.container_tour .form input[type=email]:focus-visible,
.container_tour .form input[type=number]:focus-visible,
.container_tour .form select:focus-visible,
.container_register .form input[type=text]:focus-visible,
.container_register .form input[type=password]:focus-visible,
.container_register .form input[type=email]:focus-visible,
.container_register .form input[type=number]:focus-visible,
.container_register .form select:focus-visible,
.container_my .form input[type=text]:focus-visible,
.container_my .form input[type=password]:focus-visible,
.container_my .form input[type=email]:focus-visible,
.container_my .form input[type=number]:focus-visible,
.container_my .form select:focus-visible,
.forgot_input input:focus-visible,
.forgot_input select:focus-visible {
  border-bottom: 1px solid #000 !important;
}


.form select {
  width: 100%;
  background: url(@/assets/images/icon_arrow-down.png) no-repeat calc(100% - 1em) center/11px;
  color: #000;
}

.list_label {
  display: block;
  padding-bottom: 12px;
}
.rsv-view .list_label  {

}
.list_label span,
.modal_view_name > span {
  display: inline-block;
  width: 1px;
  height: 11px;
  margin:0 12px;
  background-color: #000;
}

div.no_mt {
  margin-top: 50px;
}

div.no_mt:first-child {
  margin-top: 0;
}

.list_info {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 11px 0;
  /* max-width: 30%; */
  text-indent: 5px;
}

.list_info::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e4e4e4;
}
.container_ticket .container_body-tit {padding-bottom: 10px;}

.container_ticketsumm .form .form_item {
  height: 42px;
  column-gap: 40px;
}

.container_ticketsumm .form_label::after {
  background-color: #000;
}
.container_ticket .form input[type="checkbox"],
.container_ticket .form input[type="radio"] {border-radius: 4px !important;}
  
.summ-notice {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  padding: 60px 0;
}

.summ-notice strong {
  display: inline-block;
  margin-bottom: 10px;
}

.summ-notice span {
  color: var(--color-blue);
}

.summ-notice img {
  position: relative;
  top: -2px;
  width: 20px;
  margin-right: 5px;
}

/* 회원가입 */
.container_register .form,
.container_my .form {
  max-width: unset;
}

.form .form_inner {
  max-width: 1028px;
  margin: 0 auto;
}
.form_info {
  display: flex;
  width: 100%;
  max-width: 70%;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
}
.form .bb {
  padding-bottom: 30px;
  border-bottom: 1px solid #d3d3d3;
}
.useId_wrap {
  position: relative;
}
.form_item__wrap {
  display: flex;
}
.form_item__wrap #countryCode{
  width: 160px;
  margin-right: 20px;
}
.form_item__wrap #mobile{
  width: 319px;
  margin-right: 20px;
}
.register_pw,
.register_name,
.register_corp,
.ticket_corp {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
}
.register_pw .form_item,
.register_corp .form_item{
  width: 500px;
  margin-bottom: 0;
}
.vip-reservation .rsvtour_request .form_item {width: 344px}
.vip-reservation .form_item2 {width: 50%}
.vip-reservation .time-dropdown {
  position: relative;
  display: inline-block;
}
.time-dropdown-container {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  margin-top: 5px;
  padding: 15px 20px 20px;
}
.time-dropdown-header {
  display: flex;
  justify-content: space-between;
}

.time-dropdown-header .close-btn {
  cursor: pointer;
}

.time-dropdown-content {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.time-dropdown-content.admin {
  grid-template-columns: repeat(5, 1fr);
}

.time-dropdown-content .time-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #000;
  border-radius: 20px;
  /* background-color: #1428A0;
  color: white; */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  text-align: center;
}

.time-dropdown-content .time-button.selected {
  background-color: #1428A0;
  color: white;
}

.time-dropdown-content .time-button .tilde {
  margin-left: 2px;
  line-height: 1; /* 물결표의 수직 중앙 정렬 조정 */
  transform: translateY(25%);
}

.time-dropdown-content .time-button:hover {
  background-color: #1428A0;
  color: white;
}

.useId_wrap input[type="text"],
.useId_wrap select,
.register_corp .form_item select {width: 239.5px;}
.register_name {width: 499px;}
.register_name > div {
  width: 239.5px;
}
.form.signup .form_item .useId_wrap { margin-right: 20px;}
.form.signup .form_item .useId_wrap { display: flex; flex-wrap: nowrap; align-items: center; justify-content: flex-start  }
.rsvtour_request {
  display: flex;
  margin-bottom: 100px;
  justify-content: space-between;
}
.rsvtour_request > .form_item > div:has(#time) {margin-bottom: -50px;}
.rsvtour_items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.rsvtour_items > .form_item {width: 499px;}
.rsvtour_items > .form_item .register_name,
.rsvtour_items > .form_item:nth-last-of-type(2) {margin-bottom: 0;}
/* .form.signup .form_item .useId_wrap input { width: calc(45% - 11px);}
.form.signup .form_item .useId_wrap select { width: calc(55% - 11px);}
.form.signup .form_item .useId_wrap > div > * { width: 100%;} */

/* .form.signup .form_item .useId_wrap { width: 50%; max-width: none;}
.form.signup .form_item .useId_wrap { width: 100%; max-width: none;} */
/* .form.mypage .form_item .useId_wrap { width: 35%; max-width: none;} */
/* .form.signup .form_item .useId_wrap input { width: calc(50% - 11px);}
.form.signup .form_item .useId_wrap select { width: calc(50% - 11px);} */
.form.signup .form_item .useId_wrap span { padding: 0 7px;}
/* .useId_wrap>span {
  position: absolute;
  top: 21px;
  right: 1em;
  transform: translateY(-50%);
} */

.useId_wrap #userId {
  /* padding-right: 8.5em; */
}

.account_top {
  background-image: url(@/assets/images/bg_cate-top2.png);
}

.registered,
.registered_en {
  padding-top: 100px;
  margin-bottom: 50px;
  text-align: center;
}

.registered h3,
.registered_en h3 {
  margin-bottom: 35px;
  font-size: var(--font-title);
  font-weight: 700;
}

.registered span,
.registered_en span {
  color: var(--color-blue);
}

.registered p,
.registered_en p {
  line-height: 1.8;
}


/* 티켓 */

.container_top-sub {
  display: flex;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
}

.container_top-sub a {
  font-size: var(--font-subtitle);
  font-weight: bold;
  color: #777;
}

.container_top-sub .submenu-bar {
  display: inline-block;
  width: 1px;
  height: 25px;
  background-color: var(--color-grey);
}

.container_top-sub a.active {
  color: var(--color-blue);
}

/* .information_en {display: none;} */
.information,
.information_en {
  background:#ffffff;
}
.information>div,
.information_en>div {
}

.information_process,
.information_announce,
.information_ticket {
  display: flex;

  /* justify-content: space-between; */
}
.information_inner {
  margin: 30px 0;
}
.information_inner-padding {
  padding: 60px 0 0 0;
  border:0;
}
.information_process > div:nth-child(1),
.information_announce > div:nth-child(1),
.information_ticket > div:nth-child(1) {
  border-bottom: 1px solid #ffffff;
}


.information_inner h4 {
  font-size: var(--font-title);
}

.information_inner:first-of-type {
  flex-basis: 22.346%;
  border-right: 1px solid var(--color-border);
}

/* .information_process .information_inner:nth-of-type(2),
.information_process .information_inner:nth-of-type(3) {
  flex-basis: 35%;
} */


.information_process .information_inner:nth-of-type(2),
.information_announce .information_inner:nth-of-type(2),
.information_ticket .information_inner:nth-of-type(2) {
  flex-basis: 77.654%;
  padding-left: 100px;
  padding-left: clamp(80px,5.2vw,110px);
}

.information_text strong {
  display: block;
  margin-bottom: 20px;
  font-size: var(--font-subtitle);
}

/* .information_text p {
  font-weight: 500;
} */

.information_ticket table tr {
  border-bottom: 1px solid #E4E4E4;
}

.information_ticket table td,
.information_ticket table thead th {
  border-right: 1px solid #E4E4E4;
}

.information_ticket table tr>th:last-of-type,
.information_ticket table tr>td:last-of-type {
  border-right: 0;
}

.information_ticket table thead {
  background: #eee;
  border-top: 2px solid #000;
}

.information_ticket table thead th {
  padding: 20px 0;
}

.information_ticket table tbody td {
  padding: 40px 20px;
}

.information_ticket table .table-subject {
  width: 110px;
  text-align: center;
  white-space: nowrap;
}
.information_type_process {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.information_type_process > div {
  flex-basis: 33.33333%;
}
.information_type_process .process_img {
  width:100%;
  padding:1em 0;
  background: url(../assets/images/icon_type1.svg) no-repeat center/cover;
  font-weight: bold;
}
.information_type_process .process_img2 {
  background: url(../assets/images/icon_type2.svg) no-repeat center/cover;
}
.information_type_process .process_img3 {
  background: url(../assets/images/icon_type3.svg) no-repeat center/cover;
}
.information_type_process .process_tx {
  margin:10px 0 0 -25px;
}
.application-list .table_list {
  margin-top: 30px;
}
.checkbox {
  vertical-align: middle;
}

.request_item-bar {
  margin: 0 auto 49px !important;
  /* display: flex;
  min-height: 50px;
  padding: 7px 21px 7px 34px;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  height: 50px;
  border-top: 1px solid #555; */
  font-size: var(--font-title);
}
.request_item-bar .checkbox {font-size: var(--font-default);}
.ticket-request .form_inner {padding: 0;}
.btn-manual {
  width: 320px;
  padding: 15px 0;
  border-radius: 25px;
  background-color: var(--color-blue);
  color: #fff;
}

.container_ticketsumm .list_label::after {
  background: #000;
}
.information_process-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 30px
}
.information_process-box4 {
  flex-direction: row;
  row-gap: 100px;
  justify-content: space-between;
}
.information_process-box4::after  {
  content:"";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.information_process-box .process_item {
  display: flex;
  column-gap: 30px;
  min-height:105px;
  flex-basis: calc(50% - 15px);
}
.information_process-box .process_item4 {
  display: flex;
  column-gap: 30px;
  min-height:105px;
  flex-basis: calc(25% - 15px);
  background: url(@/assets/images/icon_paging-end.svg) no-repeat 95%/13px; 
}
.information_process-box .no_background_item4 {
  background:none;
}
.information_process-box .process_item4 div {
  position: relative;
  padding-right:30px;
  width:100%;
  text-align:center;
}
.information_process-box .process_item4 div .process_item-no {
  position: absolute;
  left:0;
  top:0;
}


.information_process-box .process_item .enter {padding-bottom: 10px;}
.information_process-box .process_item img {max-width: 60px;}
.process_item-no{
  display: inline-block;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  color: #0077C8;
  background-color: #F5F5F5;
  border-radius: 5px;
  text-align: center;
}

/* VIP 투어 */
.tourMap {width: 100%;}
.vip-reservation .form {
  max-width: unset;
}
.vip-reservation .form_inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.vip-reservation .form_inner .rsvtour_request{margin-bottom: 0;}
.vip-reservation .form_item {
  width: 499px;
  margin-bottom: 0;
}
/* .vip-reservation .form .form_item > div {width: 100%;} */
.vip_reservation_item {
  padding: 10px 0 0 0;
}

.vip-reservation>div:nth-last-of-type(2) {
  padding: 10px 0 0;
}

.vip-reservation-tit,
.request_item-bar {
  display: flex;
  width: 100%;
  max-width: 1030px;
  padding-bottom: 10px;
  margin: 0 auto 30px;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-blue);
}

.vip_invitees {
  position: relative;
  display: flex;
  /* margin-bottom: 30px; */
  /* row-gap: 15px; */
  flex-direction: column;
}
/* .vip_invitees .btn-plus {
  position: absolute;
  right: 0;
  top: 38px;
} */
.vip_invitee {
  display: flex;
  column-gap: 20px;
  flex-direction: column;
  /* justify-content: space-between; */
}
/* .vip_invitees > .vip_invitee:last-of-type {padding-bottom: 30px;} */

.num_invite { position: relative }
.num_invite span {
  /* display: block;
  content: '';
  white-space:pre;
  position: absolute;
  bottom: -35px;
  left: -5px;
  width: 600px;
  line-height: 15px;
  font-size: 13px;
  color: #f00; */
}

.vip_invitee > div {width: 100%;}
/* .vip_invitee > p {margin-bottom: 19px;} */
.vip_invitee .form_item__wrap {
  justify-content: space-between;
  align-items: center;
}
.vip_invitee .form_item__wrap > div input {width: 280px;}
.btn-remove {
  display: inline-block;
  width: 50px;
  aspect-ratio: 1/1;
  min-width: 50px;
  min-height: 50px;
  border-radius: 4px;
  color: #fff;
  background: url(@/assets/images/icon_remove-w.svg) no-repeat center/18px #000;
}

.reservation-list .table_list2 thead tr {
  border-bottom: 1px solid #666;
}

.reservation-list .table_list2 thead tr:last-of-type {
  border-bottom: 0;
}

.reservation-list .table_list2 thead th {
  border-right: 1px solid #D3D3D3;
  border-radius: 0.5px;
  font-weight: 300;
}

.reservation-list .table_list2 td {
  border-right: 1px solid var(--color-border);
}

.reservation-list .table_list2 tbody tr {
  border-bottom: 1px solid var(--color-border);
}

.reservation-list .table_list2 tbody td {
  padding: 10px;
}

.reservation-list .table_list2 td:last-of-type,
.reservation-list .table_list2 thead tr:first-of-type th:last-of-type {
  border-right: 0;
}

/* FAQ */
.faq_items_en {
  display: flex;
  flex-direction: column;
}

/* .faq_items.on{ display: block; }
.faq_items_en.on{ display: block; } */


.faq_item {
  border-bottom: 1px solid var(--color-border);
}

.faq_item button {
  display: block;
  font-size: var(--font-subtitle);
  padding: 24px 30px 25px 20px;
  width: 100%;
  background: url(@/assets/images/icon_plus-b.png) no-repeat calc(100% - 20px) center;
  text-align: left;
  font-weight: 500;
}

.faq_item button.on {
  /* margin-bottom: 25px; */
  background: url(@/assets/images/icon_minus-b.png) no-repeat calc(100% - 20px) center;
}

.faq_item button .text-blue {
  margin-right: 40px;
  font-size: 24px;
  font-weight: bold;
  color: var(--color-blue);
}

.faq_item-toggle {
  display: none;
}

.faq_item-toggle.on {
  display: block;
  padding: 30px 80px;
  background-color: #F7F7F7;
  word-break: keep-all;
  line-height:150%;
}

/* 비즈니스 라운지 */
.floorPlan_img img {width: 100%;}
.btn-guidebook {
  display: inline-block;
  width: 325px;
  padding: 10px 0;
  border-radius: 25px;
  background-color: #212121;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.room_date-wrap {
  display: flex;
  justify-content: space-between;
}
.room_date {
  height: 52px;
  line-height: 52px;
  flex: 1;
  background-color: #F5F5F5;
  text-align: center;
}
.room_date .active {
  background-color: #000;
  color: #fff
}
.room_date a{
  display: block;
  width: 100%;
  height: 100%;
  font-weight: bold;
}
.room_timetable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; 
  text-align: center;
}
.room_timetable thead {
  position: relative;
  background-color: transparent;
  color: #fff;
}
.room_timetable thead th {
  padding: 12px 0;
  border-right: 1px solid #6E6E6E;
  border-bottom: 1px solid #6E6E6E;
  font-weight: normal;
  position: -webkit-sticky;
  position: sticky;
}
.room_timetable thead th.one {
  top: 0;
  background-color: #000;
  z-index: 1;
}
.room_timetable thead th.two {
  top: 43px;
  background-color: #000;
  z-index: 1;
}

.container_adm .room_timetable thead th.one {
  top: 70px;
}
.container_adm .room_timetable thead th.two {
  top: 113px;
}

.room_timetable thead th:last-of-type {border-right: 0;}
.room_timetable thead tr:last-of-type th{border-bottom: 0;}
.room_timetable thead::after {
  /* content:""; */
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  /* background-color: #212121; */
  border-radius: 8px;
  z-index: -1;
}
.room_timetable td {
  padding:10px 5px;
  border-bottom: 1px solid #D3D3D3;
}

.room_timetable td.one {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #fff;
}
.room_timetable td.two {
  position: -webkit-sticky;
  position: sticky;
  left: 50.76px; 
  background-color: #F5F5F5;
}

.room_timetable .br {
  border-right: 1px solid #D3D3D3;
}
.room_timetable .table_point {
  background-color: #F5F5F5;
}
.time_approved {
  width: 100%;
  height: 28px;
  background-color: var(--color-blue);
  border-radius: 50px;
}
.time_process {
  width: 100%;
  height: 28px;
  background-color: #13D4DF;
  border-radius: 50px;
}
.time_blank {
  width: 100%;
  height: 28px;
  border-radius: 50px;
  color: #e7e7e7;
  font-weight: normal;
}
.time_nope {
  width: 100%;
  height: 28px;
  background-color: var(--color-grey);
  border-radius: 50px;
}
.room_notice-wrap {
  /* display: flex;
  justify-content:space-between; */
}
.room_notice-wrap .room_notice-text {
  /* flex-basis: 49%; */
  padding-top: 20px;
  line-height:150%;
  letter-spacing: 0.05em;
  border-top: 1px solid #000;
}
.timetable_label-wrap {
  display: flex;
  justify-content: right;
  column-gap: 20px;
  align-items: center;
  font-family: 'Samsung Sharp Sans', sans-serif;
}
.timetable_label {
  display: inline-block;
  width: 25px;
  height: 14px;
  margin-right: 10px;
  border-radius: 20px;
  vertical-align: middle;
}
.timetable_label-appr {background-color: #13D4DF;}
.timetable_label-proc {background-color: var(--color-blue);}
.timetable_label-blank {border:1px solid #000;box-sizing: border-box;}
.timetable_label-nope {background-color: var(--color-grey);}
.room_notice-text > strong {
  display: inline-block;
}
.floorPlan_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.floorPlan_top-left {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.floorPlan_top-left button {color: #777}
.floorPlan_top-left button.on {color: var(--color-blue)}

.floorPlan_top .submenu-bar {
  display: inline-block;
  width: 1px;
  height: 15px;
  background-color: #BDBDBD;
}
#modalFloorPlan .btn-modalclose {
  position: absolute;
  top: 20px;
  right: 20px;
}
#modalTimeChosen .modal-body {
  padding: 40px 65px;
  text-align: center;
}
/* #modalTimeChosen h3 { font-size: 21px; } */
.hoursChosen_wrap {
  display: flex;
  justify-content: space-around;
}
.hoursChosen {
  display: flex;
  text-align: left;
  column-gap: 10px;
}
.hoursChosen label {font-size: var(--font-desc);}
.form_info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 70%;
  column-gap: 20px;
}
.room-reservation-desc {
  margin-bottom: 49px;
  line-height: 1.667;
}
/* .form_info p{
  position: relative;
  display: inline-block;
  width: 100%;
  text-indent: 5px;
}
.form_info p::after{
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e4e4e4;
} */

/*** 모달 ***/
.modal-content {
  padding: 30px;
}

.modal-content-p0 {
  padding: 0;
}

.modal-content-p0 .modal-body {
  padding: 40px 45px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-border);
}

.modal-title {
  position: relative;
  font-size: var(--font-title);
  padding-bottom: 8px;
}

.modal-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: #000;
}
.modal_view {
  width: 100%;
  height: 100%;
}

.modal_view li.view-half {
  /* float: left;
   width: calc(50% - 10px);
  width: 284px;
  min-width: 284px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 25px; */
}

.modal_view li.view-half:nth-of-type(2n) {
  /* float: right; */
}

.modal_view li.view-half:nth-last-of-type(1),
.modal_view li.view-half:nth-last-of-type(2) {
  margin-bottom: 0;
}

.modal_view .modal_view_name {
  display: block;
  width: 284px;
  min-width: 284px;
  padding-bottom: 12px;
  border-bottom: 1px solid #bdbdbd;
}

.modal_view .modal_value {
  display: block;
  padding: 15px 0;
  /* margin-bottom: 15px; */
  border-bottom: 1px solid var(--color-border);
}
.modal_view {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.modal_view .list_label {width: 100%;}
.modal_view .view-four {width: 300px;}
.modal_view .view-four:has(.list_label) {width: 100%;}
/* 
.modal_view li.view-third {
  float: left;
  width: calc(33.33333% - 10px);
  margin-right: 10px;
  margin-bottom: 25px;
}

.modal_view li.view-third:nth-of-type(3n) {
  margin-right: 0;
}

.modal_view li.view-third:nth-last-of-type(1),
.modal_view li.view-third:nth-last-of-type(2),
.modal_view li.view-third:nth-last-of-type(3) {
  margin-bottom: 0;
}

.modal_view li.view-four {
  float: left;
  width: calc((100% - 140px)/3);
  margin-right: 20px;
  margin-top: 40px;
}

.modal_view li.view-four:nth-of-type(4n) {
  margin-right: 0;
}

.modal_view li.view-four:nth-of-type(4n+1) {
  width: 80px;
} */

.modal-footer {
  text-align: center;
}

.modal-notice {
  line-height: 1.6;
}
.modal-notice p {text-align: left;}

.modal-notice span {
  color: var(--color-blue);
}

.modal-notice-tit {
  text-align: center;
}

.modal-notice-tit img {
  position: relative;
  top: -2px;
  width: 20px;
  margin-right: 5px;
}

.modal-notice-tit strong {
  font-size: var(--font-subtitle);
  letter-spacing: -0.6px;
}

.spsans {
  position: relative;
  top: 1px;
}

.form_item_chk {}

.alert {
  padding: 7px 0;
  margin-top: 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  line-height: 13px;
  font-size: 13px;
}

.alert-danger {
  color: #F22525;
}

.alert-danger::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  padding-left: 5px;
  background: url(@/assets/images/icon_chk-warn.svg) no-repeat left center;
  vertical-align: middle;
}

.invalid-feedback {
  display: none;
  position: absolute;
  padding: 10px 0;
  /* border: 1px solid transparent;
  border-radius: 4px; 
  line-height: 13px; */
  letter-spacing: -0.25px;
  font-size: 13px; 
  color: #f22525; 
  }
.invalid-feedback::before {
    position: relative;
    top:-1px;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    padding-left: 0.5em;
    background: url(@/assets/images/icon_chk-warn.svg) no-repeat left center;
    vertical-align: middle;
}
.invalid-feedback-ex {
  padding:5px 0 0 3px;
  font-size:0.95em;
  color:#777;
}
.invalid-feedback-ex span {
  font-size:1.025em;
  font-weight:500;
  color:#353535;
}
/* .is-invalid {margin-bottom: 17px;} */
.is-invalid .invalid-feedback { display: block; }
.invalid-feedback::after {
  content:"";
  display: block;
  width: 100%;
  height: 37px;
}

.not_found {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.not_found_contents {
  max-width: 50%;
  color: #fff;
}

.not_found_title {
  margin-bottom: 60px;
  text-align: center;
}

.not_found_title h1 {
  font-size: 90px;
}

.not_found_title h3 {
  font-size: 32px;
  font-weight: normal;
}

.not_found_btn_wrap {
  text-align: center;
}

.not_found_btn_wrap a {}

.not_found_btn_wrap .btn-home {
  margin-top: 15px;
  width: 100%;
}


.paging li {
  display: inline;
}

.container_faq .faq_tit_left_wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.container_faq .faq_tit_left_wrap h3 {position: relative;top:-3px; display: inline; margin-right: 15px;}
.container_faq .faq_tit_left_wrap input{position: relative;top:-3px;   margin-right: 7px;border-radius:4px !important;}
.container_faq .faq_tit_left_wrap label {
  margin-left: 18px;
  line-height: 20px;
  margin-right: 5px;
}
.container_faq .faq_tit_left_wrap label input[type="checkbox"]:checked,
.container_faq .faq_tit_left_wrap label input[type="radio"]:checked {background: url(@/assets/images/checked.png) no-repeat 60% center;}

.no_data .item-icon > * {font-size: 80px;}
.no_data .item-icon {display:none;}
.timetable_date {
  position: relative;
  margin: 30px auto ;
  width: 420px;
  font-family: 'Samsung Sharp Sans';
}
/* .table_list td .btn-view,
.table_list td .btn-cancel-r,
.table_list td .btn-modify {
  display:inline-block;
  width:90%;
  font-weight:bold;
} */

.timetable_date .slick-slide { color: #777; line-height: 30px; font-size: 16px; text-align: center; }
.timetable_date .slick-slide.slick-active { position: relative; color: #000; font-size: 24px; font-weight:700;}
.timetable_date .slick-slide.slick-active::after { display: block; content: ''; position: absolute; bottom: 0px; left: 50%; width: 85%; height: 2px; background-color: #000; transform: translateX(-50%);}
.timetable_date .slick-arrow { z-index:  200;}
.timetable_date .slick-arrow.slick-prev { position: absolute; top: 0; left: 100px;  width: 30px; height: 30px; background: #fff url('@/assets/icons/arrow-next.png') no-repeat 50% 55%; transform: none; transform: rotate(180deg);}
.timetable_date .slick-arrow.slick-prev::before{ display: none; }
.timetable_date .slick-arrow.slick-next { position: absolute; top: 0; right: 100px;  width: 30px; height: 30px; background: url('@/assets/icons/arrow-next.png') no-repeat 50% 20%; transform: none;}
.timetable_date .slick-arrow.slick-next::before{ display: none; }
@media print 
{
    @page {
      size: A4; /* DIN A4 standard, Europe */
      margin:10mm;
    }
    html, body {
      width: 210mm;
      height: 297mm;
      font-size: 11px;
      background: #FFF;
      overflow:visible;
    }
    body {
      padding:10mm;
    }
}

.Toastify__toast-container {
  z-index: 999999;
}

/************************************* 반응형
@media (max-width: 1200px) {

  :root {
    --font-large: 30px;
    --font-title: 24px;
    --font-subtitle: 16px;
    --font-default: 13px;
    --font-desc: 11px;
  }
  .index_container {
    width:90%;
  }
  ul.index_inenr {
    column-gap: 30px;
  }
  .container {
    max-width: 95%;
  }

  .container_top .top-title {
    white-space: initial;
  }
}
@media (max-width: 820px) {
  .index_title {
    padding-bottom: 30px;
  }
  ul.index_inenr {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
  }
  .login_no_board {
    padding-top:35px;
  }
}
@media (max-width: 740px) {
  #header {
    padding-right:20px;
    padding-left:20px;
  }
}
@media (max-width: 580px) {
  #header {
    display:inline-block;
    height:auto;
    padding:30px 20px;
    border:none;
  }
  #header .header_logo {
    width:35%;
  }
  .index_title {
    font-size:13vw;
  }
  .index_container {
    position: relative;
    padding:60px 0 30px 0;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }
  .footer_wrap.home {
    position: relative;
  }
  .login_wrap-inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
  }
  .login_wrap-inputs > div {
    width: 100%;
  }
  .login_wrap-inputs > div:nth-child(2) {
    margin-top:10px;
  }
}
 */

 .react-datepicker-popper{
  z-index: 100;
 }

 .ticket-request-info {
  padding: 50px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  text-align: center;
 }

 .ticket-request-info .date {
  margin-bottom: 30px;
  font-weight: bold;
  color: #777777;
  line-height: 20px;
  font-size: var(--font-subtitle);
 }

 .ticket-request-info .date strong {
  color: #0077C8;
 }

 .ticket-request-info p {
  line-height: 40px;
  
 }

 .infor_day {}
 .infor_day .inner_d {
  display:inline-block;
  width:170px;
 }
 .infor_day .inner_d2 {
  display:inline-block;
  margin-left:35px;
 }

 .room_modal_img {
  width: 585px;
  margin-left:-32.5px;
  text-align:center;
 }

 .room_modal_img > div {
margin:0 auto
 }

/***********************************
- 오픈전 팝업 닫기
***********************************/
.user_id_datalist {
  position: absolute;
  top: 50px;
  background: #fff;
  box-shadow: 0 1px 6px #0003;
  /* transition: opacity .15s ease-in-out, height .25s linear; */
  border-radius: 4px;
  /* width: 360px; */
  width: max-content;
  min-width: 48%;
  padding-right: 10px;
  left: -1px;
}

.user_id_datalist ul {
  list-style: none;
}

.user_id_datalist ul li {
  line-height: 40px;
  padding-left: 15px;
}

.user_id_datalist ul li:hover {
  background-color: #E4E4E4;
  cursor: pointer;
}

.modal_openevent {
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(0,0,0, 0.8);
  z-index:999999;
}
.modal_openevent .openevent_box {
  position: absolute;
  left:50%;
  top:50%;
  padding: 100px 160px;
  /* width:820px; */
  background:#fff;
  border-radius:5px;
  transform: translate(-50%, -50%);
} 
.modal_openevent .openevent_box .inner_title {
  text-align:center;
  font-size:40px;
}
.modal_openevent .openevent_box .inner_title > span {
  color: var(--color-blue);
}
.modal_openevent .openevent_box .inner_text {
  padding:20px 0 10px 0;
  text-align:center;
  font-size:22px;
  line-height:160%;
}
.modal_openevent .openevent_box .inner_text2 {
  padding:10px 0 0 0;
  text-align:center;
  /* font-size:var(--font-title); */
  font-size:21px;
  line-height:160%;
  font-weight: bold;
  color: var(--color-blue);
}
.modal_openevent .openevent_box .inner_text2 span {
  /* color: var(--color-grey); */
  font-size:19px;
  color: #000;
}
.modal_openevent .openevent_box .inner_btn {
  text-align:center;
}
/***********************************
- 오픈전 팝업 닫기
***********************************/



/******** 230714 추가 *************/
.container_tour {padding: 0 0 120px;}
.container_tour .form input[type=text],
.container_tour .form input[type=password],
.container_tour .form input[type=email],
.container_tour .form input[type=number],
.container_tour .form select {border-bottom: 1px solid var(--color-border)}

/* 예약 페이지 */
/* .rsv-rsv .vip_invitee div {width: unset;} */
.rsv-rsv .form_inner.bb, .rsv-edit .form_inner.bb {justify-content: unset;border-bottom: 0;}
.rsv-rsv .form_inner.bb .room-reservation-desc,
.rsv-edit .form_inner.bb .room-reservation-desc {width: 100%;}
.rsv-rsv .vip_invitees, .rsv-edit .vip_invitees {width: 100%;}
/* .rsv-rsv .vip_invitees .vip_invitee:first-of-type .btn-remove {display: none;} */
.rsv-rsv .vip_invitees .vip_invitee:last-of-type {margin-bottom: 0 !important;}
.rsv-rsv .vip_invitee .form_item__wrap, .rsv-edit .vip_invitee .form_item__wrap {position: relative;justify-content: unset;}
.rsv-rsv .vip_invitee .form_item_wrap div,.rsv-edit .vip_invitee .form_item_wrap div {width: 300px;min-width: 300px;}
.rsv-rsv .vip_invitee .form_item__wrap>div input, .rsv-edit .vip_invitee .form_item__wrap>div input {width: 286px;}
/* .vip_invitee div {width: 286px;} */
/* .rsv-rsv .vip_invitee {display: block;} */
/* .rsv-rsv .vip_invitee .form_item__wrap .btn-remove {position: absolute; right: 0;} */

/* 뷰 페이지 */
.rsv-view .list_info::after {background: var(--color-border);}
.rsv-view .modal_view .view-four:has(.modal_value) {width: 329px;}
.rsv-view .modal_view .view-four:last-of-type,
.rsv-view .modal_view .view-four:nth-last-of-type(2),
.rsv-view .modal_view .view-four:nth-last-of-type(3) {margin-bottom: 0 !important}

/* 230717 추가 */
.vip_invitee p {width: 40px;min-width: 40px;}

/* 230718 추가 */
.focused {color: var(--color-blue)}
@media print {
  .rsv-view .form {padding: 0;}
  .vip-reservation .form_item {width: 48%;}
  .vip-reservation-tit.mb50 {margin-bottom: 25px !important;}
  .form_inner.mb100 {margin-bottom: 50px !important;}
  .vip-reservation .form_item.mb40 {margin-bottom: 20px !important;}
  .modal_view .view-four {width: 23% !important;}
  .modal_view .view-four:has(.list_label) {padding-top: 2em;}
  .modal_view .view-four.mb40 {margin-bottom: 0 !important;}
}

/* 230727 추가*/
.container_register .btn-cancel,
.ticket_form .btn-cancel {
  display: inline-block;
  width: 100%;
  max-width: 200px;
  height: 50px;
  border-radius: 50px;
  background-color: #000;
}

/* 230801 */

.current_issues i {
  /* text-indent: 9px; */
  font-style: normal;
  color: #808080;
}

/* 230803*/
.ticket_email {
  display: flex;
  align-items: flex-end;
}
.form.ticket_form .form_item .ticket_email_input {
  width: 499px;
  margin-right: 20px;
}
.ticket_corp .form_item {
  width: 500px;
  margin-bottom: 0;
}
.ticket_corp .form_item select {width: 239.5px;}
.mpMobile-phone select {
  width: 160px;
  margin-right: 20px;
}
.mpMobile-phone input[type="text"] {
  width: 319px;
  margin-right: 20px;
}
.ticket_form .btn-delete {
  display: inline-flex;
  width: 118px;
  height: 42px;
  padding: 0;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.request_item-bar_no {
  line-height: 42px;
  font-size: var(--font-default);
  color: var(--color-blue);
}
.modal-footer button {
  width: 200px;
  height: 50px;
  padding: initial;
  line-height: initial;
}
.application-list .table_list thead th {font-weight:700;}
/* .application-list .table_list .btn-view,
.application-list .table_list .btn-cancel-r {
  display: flex;
  width: 110px;
  height: 38px;
  padding: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
} */
#modalTicketView .modal-header {padding: 10px 10px 20px;}
#modalTicketView .modal-body {padding: 10px;}
#modalTicketView .modal_view li.view-half {
  display: flex;
  width: 100%;
  margin-bottom: 27px;
  flex-wrap: nowrap;
  align-items: flex-end;
}
#modalTicketView .modal_view_name {
  width: 284px;
  min-width: 284px;
  margin-right: 20px;
  border-bottom: 1px solid #bdbdbd;
}
#modalTicketView .modal_view .modal_value {
  display: block;
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px solid #E4E4E4;
}
.ticketview_desc {
  padding-bottom: 30px;
  line-height: 1.6;
  border-bottom: 1px solid #9A9A9A;
}
.forgot_input select {border-radius: 0;}

#modalCancel .modal-header {
  position: absolute;
  right: 0;
  z-index: 1;
}
#modalCancel .modal-content-p0 .modal-body p {line-height: 1.66;}
.vip-reservation-tit h4 {font-size: var(--font-title);}
.assitance_added {
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 20px;
  bottom: -100px;
}
.btn-success {min-width: 118px;}
.container_top button,
.index_container button {font-size: var(--font-16);}
.information_inner h4 {font-size: var(--font-title);}
.modal_openevent .openevent_box .inner_text {font-size: clamp(20px,1.146vw,26px);}
h3.mo_h3 {font-size: clamp(22px,1.25vw,26px);}
#root {min-width: 1200px;}
.container {max-width: 1300px;}
.form .form_inner,
.vip-reservation-tit,
.request_item-bar {max-width: 1100px;}
.hoursChosen label {line-height: 1.54;}
.container_top .ticket-request .form .form_item,
.rsvroom_items>.form_item {width: 499px;}
.rsvroom_items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.table_list2 .btn-cancel-r {
  font-size: var(--font-default);
}
.btn-modify,
.btn-cancel,
.table_list .btn-cancel-r {
  display: inline-flex;
  width: 144px;
  height: 34px;
  align-items: center;
  justify-content: center;
}
.btn-cancel-r,
.btn-modify-r,
.btn-view {
  display: inline-flex;
  width: 100%;
  width: clamp(68px,4.7vw,90px);
  height: 34px;
  align-items: center;
  justify-content: center;
}
.request_meeting {
  display: flex;
  justify-content: space-between;
}
.register_name {margin-bottom: 0;}
.bar {background-color: #000;}
.assitance_added .btn-remove {
  position: absolute;
  top: 50%;
  right: 0;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.other__wrap {
  position: relative;
}
.other__input {
  position: absolute;
  width: 48%;
  top: 100%;
  right: 0;
}
.other__input > input[type="text"] {position: relative;}
#header .header_links a, #header .header_links button {white-space: nowrap;}

/* 231116 */
.information_inner .listing {
  display: flex;
  word-break: keep-all;
}
.information_inner .listing > span {margin-right: 4px;}

label[for="mobilityAssistanceFormData.expectedService"] {white-space: nowrap;}
.tourMap {max-width: 460px;}
.roomLayout {max-width: 600px;}

.select.icon_cal {background: url(@/assets/images/icon_s5.svg) no-repeat calc(100% - 1em) center/16px #fff;}

/* 캘린더 화살표 수정 김강연 - 240618*/
:root {
  --datepickr-bg-color: #fff;
  --datepickr-bg-color2: #1428A0;
  --datepickr-border-color: #000;
  --datepickr-ft-color: #fff;
}
.react-datepicker {
  border: 1px solid var(--datepickr-border-color);
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  left: -17px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: var(--datepickr-border-color);
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: var(--datepickr-ft-color);
  border-width: 2px 2px 0 0;
}
.react-datepicker__header {
  text-align: center;
  background-color: var(--datepickr-border-color);
  border-bottom: 1px solid var(--datepickr-border-color);
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__current-month, 
.react-datepicker-time__header, 
.react-datepicker-year-header {
  color: var(--datepickr-ft-color);
}
.react-datepicker__day-name {
  color:var(--datepickr-ft-color);
}
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--datepickr-bg-color2);
  border-radius: 20px;
}
.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range {
  background-color: var(--datepickr-bg-color2);
  border-radius: 20px;
}


/*** 2024 김강연 **/
.ft_blue_btn {
  background: var(--datepickr-bg-color2);
  color:#fff;
  padding:6px 16px 6px 16px;
  border-radius:25px;

  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}
.ft_blue_btn:hover {
  background:#000;
}
.information_process-box .mr10 {margin-top:10px;}


.register-modal-container {
  display:flex;
  justify-content: space-between;
}
.register-modal-bx {
  position: relative;
  width:30%;
  border-radius:10px;
}
.register-modal-sp1 {
  border:1px solid var(--datepickr-bg-color2);
}
.register-modal-sp2 {
  border:1px solid var(--datepickr-bg-color2);
}
.register-modal-sp3 {
  border:1px solid #A9180A;
}
.register-modal-sp1 .rmb-dv_01 {color: var(--datepickr-bg-color2);}
.register-modal-sp2 .rmb-dv_01 {color: #d0d4ec;}
.register-modal-sp3 .rmb-dv_01 {color: #A9180A;}

.register-modal-sp1:after,
.register-modal-sp2:after {
  position: absolute;
  top: calc(50% - 18px);
  right:-27px;
  background: url('@assets/images/pop_next.png') no-repeat top/100%;
  content: "";
  width:12px;
  height:15px;
}


.rmb-dv_01 {
  position: absolute;
  left:50%;
  top: -22px;
  transform: translateX(-50%);
  display:inline-block;
  font-family: 'SamsungSharpSans-Bold';
  background:#fff;
  padding:0 30px;
  font-size:2.8em;
}
.rmb-dv_02 {
  padding-top:35px;
  padding-bottom:15px;
  font-size:1.6em;
  font-family: 'SamsungSharpSans-Bold';
  width:100%;
  text-align:center;
}
.rmb-dv_02 .inner_00 {color: var(--datepickr-bg-color2);}
.rmb-dv_02 .inner_01 {font-size:0.7em;}
.rmb-dv_03 {
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:60px;
  font-weight:600;
  font-size:1.1em;
  text-align:center;
  color: var(--datepickr-bg-color2);
  background:#F1F4F9;
}
.register-modal-sp3 .rmb-dv_02 {
  padding-top:40px;
  padding-bottom:33px;
}
.register-modal-sp3 .rmb-dv_03 {
  color: #A9180A;
  background: #f5ebea;
}
.rmb-dv_04 {

  width:100%;
  text-align:center;
}
.rmb-dv_04 .inner_01 {
  padding:15px 0;
  font-size:1.35em;
  font-family: 'SamsungSharpSans-Bold';
}
.rmb-dv_04 .inner_01 span {
  position: relative;
  bottom:-5px;
  margin:0 4px;
}
.rmb-dv_04 .inner_02 {
  padding:0 15px 0 15px;
  font-weight:600;
}
.rmb-dv_04 .inner_02 .tx1 {
  width:100%;
  display:inline-block;
  font-size:0.9em;
  font-weight:500;
  letter-spacing: -0.025em;
  padding: 15px 0 20px 0;
  word-break: keep-all;
}


.register-modal-text {
  padding:30px 0 30px 0;
}
.register-modal-text .inner_div {
  position: relative;
  font-weight:600;
  padding-left:20px;
  margin-bottom:5px;
  font-size:1.025em;
  letter-spacing: 0.035em;
  word-break: keep-all;
}
.register-modal-text .inner_div .tx1 {
  position: absolute;
  left:2px;
}
.register-modal-text .inner_div .tx-point {color: #A9180A;}

.modal-footer button.btn-send2 {
  width:100%;
  background: var(--datepickr-bg-color2);
  color:#fff;
  border-radius:50px;
  font-size:1.075em;
}
.modal-content .container_lang {
  position: relative;
  top:-7px;
  justify-content: right;
}
.container_register {
  padding:0;
}
label[for="same"], label[for="same2"] {
  color: var(--color-blue);
  font-weight: bold;
}
.bro-ft {font-weight:700;}

.application-list .btn-container {
  position: relative;
  display: inline-block;
}
.application-list .btn-container .icon-container {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: 3px;
  background: #fff;
  border-radius: 30px;
  padding: 2px;
}
.application-list .btn-container .icon-container .info-icon {
  width: 20px;
  height: 20px;
}
.application-list .btn-container .icon-container .popover {
  position: absolute;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  bottom: 100%;
  right: 0;
  margin-bottom: 5px;
  white-space: nowrap;
}
.application-list .btn-container .icon-container .popover::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.sp_p {
  position: relative;
  padding-left:18px;
  line-height:170%;
  letter-spacing: 0.025em;
}
.sp_p span.sp_sp {
  position: absolute;
  margin-top:-1px;
  margin-left:-18px;
}
.sp_h {
  line-height:170%;
  letter-spacing: 0.025em;
}