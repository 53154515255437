@charset "utf-8";

/******* Global *******/
:root {
  --color-blue: #1428a0;
  --color-dackblue: #1428a0;
  --color-grey_bt: #a1a1a1;
  --color-grey: #9a9a9a;
  --color-border: #BDBDBD;
  
  /* --font-large: 45px;
  --font-title: 20px;
  --font-subtitle: 18px;
  --font-default: 15px;
  --font-desc: 13px; */

  --font-large: clamp(30px,2.34vw,50px);
  --font-title: clamp(18px,1.04vw,24px);
  --font-subtitle: clamp(16px,0.93vw, 20px);
  --font-16: clamp(14.5px,0.83vw,17px);
  --font-default: clamp(13.5px,0.78vw,17px);
  --font-desc: clamp(12.5px,0.67vw,14.5px);
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

body {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: var(--font-default);
  letter-spacing: -0.1px;
}

button,
select {
  text-transform: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  outline: none;
  border: 0;
  margin: 0;
}

input {
  width: 100%;
}

textarea {
  overflow: auto;
}

button {
  font-family: 'SamsungSharpSans-Medium', 'SamsungOneKorean';
  font-weight: 700;
  background-color: transparent;
}

input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 1px solid var(--color-blue);
  border-radius: 4px;
  background: #fff;
  vertical-align: middle;
}

input[type="checkbox"].vip_tour{
  appearance: auto;
  -webkit-appearance: auto;  
  width: 16px;
  height: 16px;
  margin: 0px 5px 3px 4px;
}
input[type="checkbox"].vip_tour:nth-child(n+2){
  margin-left: 15px;
}

input,
select {
  padding: 0 9.5px;
}

input:disabled,
select:disabled {
  color: #9A9A9A;
  background-color: transparent;
  opacity: 1;
}

img {
  max-width: 100%;
  border-style: none;
  vertical-align: middle;
}

ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

span,
p,
h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}

small {
  font-size: var(--font-desc);
}

*::placeholder {
  color: var(--color-grey);
}

table {
  width:100%;
  border-collapse: collapse;
}
.ft_blue {
  color: var(--color-blue);
}
/******* Common class *******/
/* display & position */
.hide {
  position: absolute;
  left: 0;
  font-size: 0;
  color: transparent;
  width: 0;
  height: 0;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.por {
  position: relative;
}

.clear::after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
}

.one-third {
  width: 33.3333%;
}

.one-quarter {
  width: 25% !important;
}

/* spacing */
.m-cent {
  margin: 0 auto;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}
.mb100 {
  margin-bottom: 100px !important;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

/* checkbox */
/* input[type="checkbox"] {
  width: 20px;
  height: 20px;
  padding: 0;
  border: 1px solid #000;
  background: #fff;
  vertical-align: middle;
} */

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background: url(@/assets/images/checked.png) no-repeat 60% center;
  border: 1px solid transparent;
}

/* et cetera */
.overlay {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
}

.overlay.on {
  display: block;
}

.bar {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: var(--color-grey);
}

.bar-b {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #000;
}

.enter {
  display: block;
}

.warning {
  color: #f22525;
}

.nowrap {
  white-space: nowrap;
}
/*** 로그인 ***/
.login_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.login_wrap-inputs,
.login_wrap-tit {
  display: flex;
  justify-content: space-between;
}
.login_wrap .login_wrap-tit .login-links {
  text-decoration: underline;
}
.login_wrap .login_wrap-tit .login-links a{
  margin-right: 14px;
  color: #000000;
  font-size:15px;
}

.login_wrap .login_wrap-tit .login-links button{
  color: #000000;
  font-size:15px;
  text-decoration: underline;
  font-weight: normal;
}

.login_wrap-inputs > * {
  width: 48%;
}
.login_wrap input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border:1px solid #D3D3D3;
}
.login_wrap-tit > a {
  font-size: 18px;
  font-weight:bold;
}
/*** 로그인 후 ***/
.logout_wrap {
  padding:40px 0 0 0;
}
.logout_wrap > ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
}
.logout_wrap > ul > li:nth-child(2) {
  text-align:right;
  min-width: 125px;
}
.logout_wrap > ul > li > a {
  text-decoration: underline;
  text-underline-offset: 1.5px;
  font-weight: normal;
}
.logout_wrap > ul > li > button{
  text-decoration: underline;
  text-underline-offset: 1.5px;
  font-weight: normal;
}
.logout_wrap .logout_name {
  height:76px;
  line-height:76px;
  /* font-size:1.2em; */
}
.logout_wrap .logout_name > span {
  color: var(--color-blue);
}
/*** // 로그인 후 ***/

.alert-danger {
  white-space: nowrap;
}

/*** 페이징 ***/
.paging {
  text-align: center;
}

.paging .paging_first,
.paging .paging_end {
  display: inline-block;
  width: 12px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.paging .paging_first {
  position: relative;
  top: 3px;
  background-image: url(@/assets/images/icon_paging-first.svg);
}

.paging .paging_end {
  position: relative;
  top: 3px;
  background-image: url(@/assets/images/icon_paging-end.svg);
}

.paging .paging_num {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 15px;
  line-height: 32px;
  text-align: center;
  border-radius: 8px;
  font-weight: 700;
  cursor: pointer;
}

.paging .paging_current {
  color: #000;
}


/*** Buttons ***/
.buttons_center {
  text-align: center;
}

.btn-en {
  background-color: var(--color-blue);
  color: #fff
}

.btn-en {
  border-radius: 20px 0 0 20px;
}

.btn-kr {
  border-radius: 0 20px 20px 0;
}

.btn-add {
  height: 38px;
  padding: 0 35px;
  border-radius: 20px;
  background-color: var(--color-blue);
  color: #fff;
}
.btn-PDF_download {
  height: 38px;
  padding: 0 35px;
  border-radius: 20px;
  background-color: #000;
  color: #fff;  
}
.btn-delete {
  height: 38px;
  padding: 0 35px;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
}

.btn-cancel-r {
  /* padding: 6px 24px; */
  border: 1px solid #000;
  border-radius: 20px;
  background-color: #000;
  font-size: var(--font-16);
  color: #fff;
}

.btn-cancel-r.disabled {
  background: var(--color-grey);
  border: 1px solid var(--color-grey);
  color: rgba(255, 255, 255, .5);
}

.btn-detail {
  padding: 6px 24px 8px 24px;
  min-width: 110px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  font-size: var(--font-desc);
  color: #000;
}

.btn-modify {
  padding: 6px 24px 8px 24px;
  border: 1px solid var(--color-blue);
  border-radius: 20px;
  background-color: var(--color-blue);
  color: #fff;
}

.btn-modify:disabled {
  background-color: var(--color-grey);
  color: rgba(255, 255, 255, .5)
}

.btn-modify-r {
  /* padding: 6px 24px; */
  border: 1px solid var(--color-blue);
  border-radius: 20px;
  background-color: var(--color-blue);
  /* font-size: var(--font-16); */
  color: #fff;
}

.btn-modify-r:disabled {
  background: var(--color-grey_bt);
  border: 1px solid var(--color-grey_bt);
  color: rgba(255, 255, 255, .5);
}

.btn-cancel-s {
  padding: 5px 13px 5px 13px !important;
  border-radius: 20px;
  background-color: var(--color-grey_bt);
  color: #fff;
}

.btn-view {
  /* padding: 9px 25px; */
  border: 1px solid #000;
  border-radius: 20px;
  font-size: var(--font-default);
  color: #000;
}

.btn-cancel {
  padding: 15px 60px;
  border-radius: 25px;
  font-size: var(--font-16);
  /* background-color: var(--color-grey_bt); */
  background-color: #000;
  font-weight: bold;
  color: #fff;
}
.btn-line {
  display:inline-block;
  padding: 15px 60px;
  border-radius: 50px;
  width:100%;
  max-width:200px;
  height:50px;
  border: 1px solid var(--color-blue);
  background-color: #fff;
  color: var(--color-blue);  
}
.btn-black {
  display:inline-block;
  padding: 15px 60px;
  border-radius: 50px;
  width:100%;
  max-width:200px;
  height:50px;
  font-size: var(--font-16);
  font-weight: 700;
  background-color: #000;
  color: #fff;
}
.btn-back {
  display:inline-block;
  padding: 15px 60px;
  border-radius: 50px;
  width:100%;
  max-width:200px;
  height:50px;
  border: 1px solid #000;
  font-size: var(--font-16);
  font-weight: 700;
  color: #000;
}
 
.btn-done {
  padding: 15px 60px;
  border-radius: 50px;
  width:100%;
  max-width:200px;
  height:50px;
  background-color: var(--color-blue);
  color: #fff;
}
.btn-done_200 {
  padding: 15px 60px;
  border-radius: 50px;
  width:100%;
  max-width:200px;
  height:50px;
  background-color: var(--color-blue);
  font-size: var(--font-16);
  color: #fff;
}
.btn-submit {
  padding: 15px 60px;
  border-radius: 50px;
  width:100%;
  max-width:200px;
  height:50px;
  background-color: var(--color-blue);
  font-size: var(--font-16);
  color: #fff;
}

.btn-send {
  padding: 15px 60px;
  border-radius: 50px;
  width:100%;
  max-width:200px;
  height:50px;
  background-color: var(--color-blue);
  font-size: var(--font-16);
  color: #fff;
}

.btn-home {
  width:100%;
  max-width:240px;
  height:50px;
  border-radius: 50px;
  background-color: var(--color-blue);
  border: 1px solid var(--color-blue);
  color: #fff;
}

.btn-email {
  width:100%;
  max-width:240px;
  height:50px;
  border: 1px solid #000;
  border-radius: 50px;
  background-color:#000;
  color: #fff;
}
.btn-mail {
  padding: 15px 60px;
  border-radius: 25px;
  border: 1px solid #000;
  color: #000;
}

.btn-check {
  height: 42px;
  padding: 0 10px;
  width: 118px;
  border-radius: 30px;
  background-color: var(--color-blue);
  font-size: var(--font-16);
  color: #fff;
}

.btn-success {
  height: 42px;
  padding: 0 10px;
  width: 100%;
  max-width: 118px;
  border-radius: 30px;
  background-color: var(--color-grey);
  color: #fff;
}

.btn-excel {
  padding: 6px 30px;
  border-radius: 20px;
  background-color: #518227;
  color: #fff;
}

.btn-ifa {
  display: flex;
  width: 250px;
  width: max-content;
  height: 38px;
  padding: 0 1.2em;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  color: #fff;
}

.btn-login {
  width: 100%;
  height: 50px;
  background-color: var(--color-blue);
  border-radius: 4px;
  color: #fff;
}

.btn-plus {
  width: 50px;
  height: 50px;
  background: url(@/assets/images/icon_plus.png) no-repeat center/contain;
}

.btn-close {
  width: 80%;
  padding: 10px 0;
  border: 1px solid #000;
  border-radius: 25px;
  color: #000;
}
.btn_mx {
  width:100%;
  max-width:240px;
}
.btn-modalifa {
  display: inline-block;
  display: inline-flex;
  width: 280px;
  height: 54px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: var(--color-blue);
  color: #fff;
}

.btn-print {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(@/assets/images/btn_print.svg) no-repeat center/22px;
}
.btn-cancel-r:disabled,
.btn-modify:disabled {
  border:1px solid var(--color-grey);
  background-color: var(--color-grey);
  font-size: var(--font-16);
  color: rgba(255, 255, 255, .5)
}
/* 모달에서  파랑 버튼 .btn-done  넓이만 수정 */
.modal-footer button.btn-done {
  max-width:138.84px;
}

.bg_gray {
  background-color: #a1a1a1 !important;
}
.bg_red {
  background-color: #F23434 !important;
}
.bg_green {
  background-color: #00AA5A !important;
}
.bg_blue {
  background-color: #1428A0 !important;;
} 
.bg_black {
  background-color: #000 !important;
}
.bg_white {
  background-color: #fff !important;
}
.fc_white {
  color: #fff;
}