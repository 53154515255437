@charset "utf-8";
/* Samsung Sharp Sans */
@font-face {
  font-family:'SamsungSharpSans';
  font-style:normal;
  font-weight:400;
  src:url(@/assets/fonts/SamsungSharpsans.ttf) format("truetype"),
      url(@/assets/fonts/SamsungSharpsans.ttf) format("embedded-opentype");
}
@font-face {
  font-family:'SamsungSharpSans-Medium';
  font-style:normal;
  font-weight:500;
  src:url(@/assets/fonts/SamsungSharpSans-Medium.ttf) format("truetype"),
      url(@/assets/fonts/SamsungSharpSans-Medium.woff) format("woff"),
      url(@/assets/fonts/SamsungSharpSans-Medium.otf) format("opentype"),
      url(@/assets/fonts/SamsungSharpSans-Medium.eot) format("embedded-opentype");
}
@font-face {
  font-family:'SamsungSharpSans-Bold';
  font-style:normal;
  font-weight:700;
  src:url(@/assets/fonts/SamsungSharpSans-Bold.ttf) format("truetype"),
      url(@/assets/fonts/SamsungSharpSans-Bold.ttf) format("embedded-opentype");
}

@font-face { 
  font-family:'Samsung Sharp Sans';
  font-style:normal;
  font-weight:700;
  src:url(@/assets/fonts/SamsungSharpSans-Bold.eot);
  src:url(@/assets/fonts/SamsungSharpSans-Bold.eot?#iefix) format("embedded-opentype"),
      url(@/assets/fonts/SamsungSharpSans-Bold.woff2) format("woff2"),
      url(@/assets/fonts/SamsungSharpSans-Bold.woff) format("woff"),
      url(@/assets/fonts/SamsungSharpSans-Bold.ttf) format("truetype");
}
/* SamsungOneKorean */
@font-face {
  font-family:'SamsungOneKorean';
  font-style:normal;
  font-weight:normal;
  src:url(@/assets/fonts/SamsungOneKorean-400.eot);
  src:url(@/assets/fonts/SamsungOneKorean-400.eot?#iefix) format("embedded-opentype"),
      url(@/assets/fonts/SamsungOneKorean-400.woff2) format("woff2"),
      url(@/assets/fonts/SamsungOneKorean-400.woff) format("woff"),
      url(@/assets/fonts/SamsungOneKorean-400.ttf) format("truetype");
}
@font-face {
  font-family:'SamsungOneKorean';
  font-style:normal;
  font-weight:bold;
  src:url(@/assets/fonts/SamsungOneKorean-700.eot);
  src:url(@/assets/fonts/SamsungOneKorean-700.eot?#iefix) format("embedded-opentype"),
      url(@/assets/fonts/SamsungOneKorean-700.woff2) format("woff2"),
      url(@/assets/fonts/SamsungOneKorean-700.woff) format("woff"),
      url(@/assets/fonts/SamsungOneKorean-700.ttf) format("truetype");
}

/* 21-11-01 NEW KOR font */
@font-face {
font-family:'Samsung Korea Sans';
font-style:normal;
font-weight:normal;
src:url(@/assets/fonts/SamsungKoreanSans-Medium.eot);
src:url(@/assets/fonts/SamsungKoreanSans-Medium.eot?#iefix) format("embedded-opentype"),
  url(@/assets/fonts/SamsungKoreanSans-Medium.woff2) format("woff2"),
  url(@/assets/fonts/SamsungKoreanSans-Medium.woff) format("woff"),
  url(@/assets/fonts/SamsungKoreanSans-Medium.ttf) format("truetype");
}
@font-face {
font-family:'Samsung Korea Sans';
font-style:normal;
font-weight:bold;
src:url(@/assets/fonts/SamsungKoreanSans-Bold.eot);
src:url(@/assets/fonts/SamsungKoreanSans-Bold.eot?#iefix) format("embedded-opentype"),
  url(@/assets/fonts/SamsungKoreanSans-Bold.woff2) format("woff2"),
  url(@/assets/fonts/SamsungKoreanSans-Bold.woff) format("woff"),
  url(@/assets/fonts/SamsungKoreanSans-Bold.ttf) format("truetype");
}


body {
  font-family: 'SamsungOneKorean', sans-serif;
}

h1,
h2,
h3,
h4,
a,
b,
.container_top span,
button {font-family: 'SamsungSharpSans-Bold','SamsungSharpSans-Medium', sans-serif;;}

.ssone {font-family: 'SamsungOneKorean', sans-serif;}
.href {
  font-family: 'SamsungOneKorean', sans-serif;
  text-decoration: underline;
  color: var(--color-blue);
}



.sssb {font-family: 'SamsungSharpSans-Bold';}
.sssm {font-family: 'SamsungSharpSans-Medium';}
.ssss {font-family: 'SamsungSharpsans';}

.ft_400 {font-weight:400;}
.ft_500 {font-weight:500;}
.ft_700 {font-weight:700;}