@charset "utf-8";
/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Generated using the Bootstrap Customizer (<none>)
 * Config saved to config.json and <none>
 */

.modal-open {
  /* overflow: hidden; */
  overflow-y: scroll;
  padding-right: 0px !important;
}
.modal {
  display: none;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade.in {
  background: rgba(0,0,0, 0.8);
  height: 100vh;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow: none;
}
@media (max-width:720px) {
  .modal-open .modal {
    /* Pc 에서는 스크롤 감추고 가로 720 이하부터 모달 스크롤 생기도록 처리*/
    overflow: auto;
  }  
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 0;
  border-radius: 8px;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  outline: 0;
  width: 100%;
}
.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height:100%;
}
.modal-backdrop.fade {
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.modal-header {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 16.42857143px;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 20px;
  min-height: 120px;
}
.modal-footer {
  padding-bottom: 20px;
  text-align: center;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 490px;
    margin: 0 auto;
  }
  .modal-dialog_lg {width: 650px;}
  .modal-content {
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  }
  .modal-sm {width: 300px;}
}
@media (min-width: 992px) {
  .modal-lg {width: 900px;}
}
.clearfix:before,
.clearfix:after,
.modal-footer:before,
.modal-footer:after {
  content: "";
  display: table;
}
.clearfix:after,
.modal-footer:after {clear: both;}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.register-modal-body {
  padding:20px 0 0 0;
}
.modal-dialog-size {
  width:822px;
}